import { Entity, Notice, Spacer, Text, toast } from '@vapor/ui';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import * as Yup from 'yup';
import { Mail } from 'react-feather';
import { Layout } from '../layout';
import { useUser } from '../state/useUser';

let AccountSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, `A name can't be longer than 100 characters.`)
    .required('Looks like you forgot something...'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Looks like you forgot something...')
});

export default function Home() {
  let { user, update, error: userError } = useUser();

  let formik = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || ''
    },
    validationSchema: AccountSchema,
    onSubmit: values => {
      update(values)
        .then(() => toast.success('Account updated successfully'))
        .catch(err => {
          Sentry.captureException(err);
          toast.error('Could not update account');
        });
    }
  });

  useEffect(() => {
    formik.setFieldValue('name', user?.name || '');
  }, [user?.name]);

  useEffect(() => {
    formik.setFieldValue('email', user?.email || '');
  }, [user?.email]);

  return (
    <Layout pageId="login">
      {userError && (
        <>
          <Notice variant="error">Could not load your account. Please try again later.</Notice>

          <Spacer height="20px" />
        </>
      )}

      <Text size={32} weight={700}>
        Login Methods
      </Text>
      <Spacer height={10} />

      <Text size={18} weight={500}>
        Wondering about how you can log in? Worry no more.
        {user &&
          ' You can log in with ' +
            user.providers
              .map(provider => (provider == 'google' ? 'Google' : provider))
              .join(' and ') +
            '.'}
        {user && user.providers.length == 2 && ' Both are great options.'}
      </Text>

      <Spacer height="max(2vh, 10px)" />

      <Entity.Wrapper>
        <Entity.Content>
          <Entity.Field
            title="Google"
            prefix={
              <img
                src="https://cdn.onhighloop.com/friends/google.svg"
                alt="google logo"
                style={{ width: 20 }}
              />
            }
          />

          <Entity.Field
            right
            title={
              !user
                ? 'Loading...'
                : user.providers.includes('google')
                ? 'Enabled'
                : 'Not Enabled'
            }
          />
        </Entity.Content>
      </Entity.Wrapper>

      <Spacer height="10px" />

      <Entity.Wrapper>
        <Entity.Content>
          <Entity.Field title="Email" prefix={<Mail size={20} />} />

          <Entity.Field
            right
            title={
              !user
                ? 'Loading...'
                : user.providers.includes('email')
                ? 'Enabled'
                : 'Not Enabled'
            }
          />
        </Entity.Content>
      </Entity.Wrapper>
    </Layout>
  );
}
